import React, {useState} from "react";

export const ChevronDown = ({ fill, size, height, width, ...props }) => {
  return (
    <svg
      fill="none"
      height={size || height || 24}
      viewBox="0 0 24 24"
      width={size || width || 24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m19.92 8.95-6.52 6.52c-.77.77-2.03.77-2.8 0L4.08 8.95"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
      />
    </svg>
  );
};


export const BackIcon = (props) => (
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 5L3 10L8 15"
      stroke="#ffffff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 10H11C16.5228 10 21 14.4772 21 20V21"
      stroke="#ffffff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const LinkedInIcon = (props) => (

  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="24px"
    height="24px"
    viewBox="0 0 382 382"
    style={{
      enableBackground: "new 0 0 382 382",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        fill: "#0077B7",
      }}
      d="M347.445,0H34.555C15.471,0,0,15.471,0,34.555v312.889C0,366.529,15.471,382,34.555,382h312.889 C366.529,382,382,366.529,382,347.444V34.555C382,15.471,366.529,0,347.445,0z M118.207,329.844c0,5.554-4.502,10.056-10.056,10.056 H65.345c-5.554,0-10.056-4.502-10.056-10.056V150.403c0-5.554,4.502-10.056,10.056-10.056h42.806 c5.554,0,10.056,4.502,10.056,10.056V329.844z M86.748,123.432c-22.459,0-40.666-18.207-40.666-40.666S64.289,42.1,86.748,42.1 s40.666,18.207,40.666,40.666S109.208,123.432,86.748,123.432z M341.91,330.654c0,5.106-4.14,9.246-9.246,9.246H286.73 c-5.106,0-9.246-4.14-9.246-9.246v-84.168c0-12.556,3.683-55.021-32.813-55.021c-28.309,0-34.051,29.066-35.204,42.11v97.079 c0,5.106-4.139,9.246-9.246,9.246h-44.426c-5.106,0-9.246-4.14-9.246-9.246V149.593c0-5.106,4.14-9.246,9.246-9.246h44.426 c5.106,0,9.246,4.14,9.246,9.246v15.655c10.497-15.753,26.097-27.912,59.312-27.912c73.552,0,73.131,68.716,73.131,106.472 L341.91,330.654L341.91,330.654z"
    />
  </svg>

);

export const InstagramInIcon = (props) => (
  <svg
    id="instagram"
    fill="rgb(217, 50, 117)"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="24px"
    height="24px"
    viewBox="0 0 169.063 169.063"
    style={{
      enableBackground: "new 0 0 24 24",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path d="M122.406,0H46.654C20.929,0,0,20.93,0,46.655v75.752c0,25.726,20.929,46.655,46.654,46.655h75.752                           c25.727,0,46.656-20.93,46.656-46.655V46.655C169.063,20.93,148.133,0,122.406,0z M154.063,122.407                           c0,17.455-14.201,31.655-31.656,31.655H46.654C29.2,154.063,15,139.862,15,122.407V46.655C15,29.201,29.2,15,46.654,15h75.752                           c17.455,0,31.656,14.201,31.656,31.655V122.407z" />
      <path d="M84.531,40.97c-24.021,0-43.563,19.542-43.563,43.563c0,24.02,19.542,43.561,43.563,43.561s43.563-19.541,43.563-43.561                           C128.094,60.512,108.552,40.97,84.531,40.97z M84.531,113.093c-15.749,0-28.563-12.812-28.563-28.561                           c0-15.75,12.813-28.563,28.563-28.563s28.563,12.813,28.563,28.563C113.094,100.281,100.28,113.093,84.531,113.093z" />
      <path d="M129.921,28.251c-2.89,0-5.729,1.17-7.77,3.22c-2.051,2.04-3.23,4.88-3.23,7.78c0,2.891,1.18,5.73,3.23,7.78                           c2.04,2.04,4.88,3.22,7.77,3.22c2.9,0,5.73-1.18,7.78-3.22c2.05-2.05,3.22-4.89,3.22-7.78c0-2.9-1.17-5.74-3.22-7.78                           C135.661,29.421,132.821,28.251,129.921,28.251z" />
    </g>
  </svg>
);



export const FacebookInIcon = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 1024 1024"
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M715.637 960h171.897C920.348 960 960 932.759 960 898.909V125.091C960 91.243 920.348 64 887.534 64H113.716C77.004 64 64 96.892 64 125.091v773.818C64 927.109 77.004 960 113.716 960h439.012V634.182H410.181c-11.257 0-20.363-9.106-20.363-20.363V491.637c0-11.257 9.106-20.365 20.363-20.365h142.546V328.728c0-99.354 88.056-183.272 192.261-183.272h113.193c11.257 0 20.365 9.106 20.365 20.363V288c0 11.258-9.108 20.365-20.365 20.365h-72.465c-34.444 0-70.079 19.052-70.079 50.908v112h131.17a20.27 20.27 0 0 1 16.507 8.472c3.856 5.291 4.891 12.133 2.823 18.337l-40.728 122.181a20.403 20.403 0 0 1-19.33 13.919h-90.442V960z"
      fill="#2577FF"
    />
    <path
      d="M807.708 451.723h-92.071v19.549h112.288c-0.161-3.938-1.326-7.809-3.711-11.078a20.263 20.263 0 0 0-16.506-8.471zM513.629 940.451H75.445C83.3 951.952 95.599 960 113.716 960h439.012V634.183H513.63v306.268zM839.283 145.456c-0.451-10.855-9.231-19.549-20.198-19.549H705.89c-104.205 0-192.261 83.919-192.261 183.272v142.544H371.083c-11.257 0-20.363 9.108-20.363 20.365v122.181c0 11.258 9.107 20.364 20.363 20.364h18.899c-0.012-0.286-0.164-0.527-0.164-0.815V491.637c0-11.257 9.106-20.365 20.363-20.365h142.546V328.728c0-99.353 88.056-183.272 192.261-183.272h94.295z"
      fill=""
    />
    <path
      d="M900.123 65.251c12.221 10.76 20.778 24.748 20.778 40.29V879.36c0 33.85-39.651 61.091-72.467 61.091H715.637V960h171.896C920.348 960 960 932.759 960 898.909V125.091c0-29.6-30.322-54.141-59.877-59.84z"
      fill=""
    />
  </svg>
);

export const HeartIcon = ({
  fill = 'currentColor',
  height,
  width,
}) => {
  return (
    <svg
      width={width || 24}
      height={ height || 24}
      viewBox="0 0 24 24"
      fill='none'
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.62 20.81c-.34.12-.9.12-1.24 0C8.48 19.82 2 15.69 2 8.69 2 5.6 4.49 3.1 7.56 3.1c1.82 0 3.43.88 4.44 2.24a5.53 5.53 0 0 1 4.44-2.24C19.51 3.1 22 5.6 22 8.69c0 7-6.48 11.13-9.38 12.12Z"
        stroke={fill}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const MailIcon = (props) => (
  <svg
    aria-hidden="true"
    fill="none"
    focusable="false"
    height="1em"
    role="presentation"
    viewBox="0 0 24 24"
    width="1em"
    {...props}
  >
    <path
      d="M17 3.5H7C4 3.5 2 5 2 8.5V15.5C2 19 4 20.5 7 20.5H17C20 20.5 22 19 22 15.5V8.5C22 5 20 3.5 17 3.5ZM17.47 9.59L14.34 12.09C13.68 12.62 12.84 12.88 12 12.88C11.16 12.88 10.31 12.62 9.66 12.09L6.53 9.59C6.21 9.33 6.16 8.85 6.41 8.53C6.67 8.21 7.14 8.15 7.46 8.41L10.59 10.91C11.35 11.52 12.64 11.52 13.4 10.91L16.53 8.41C16.85 8.15 17.33 8.2 17.58 8.53C17.84 8.85 17.79 9.33 17.47 9.59Z"
      fill="currentColor"
    />
  </svg>
);

export const PhoneIcon = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1em"
    height="1em"
    viewBox="0 0 19 32"
    enableBackground="new 0 0 19 32"
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path
        fill="#828282"
        d="M1.5,32h16c0.827,0,1.5-0.673,1.5-1.5v-29C19,0.673,18.327,0,17.5,0h-16C0.673,0,0,0.673,0,1.5v29 C0,31.327,0.673,32,1.5,32z M1,1.5C1,1.224,1.224,1,1.5,1h16C17.776,1,18,1.224,18,1.5v29c0,0.276-0.224,0.5-0.5,0.5h-16 C1.224,31,1,30.776,1,30.5V1.5z"
      />
      <path
        fill="#828282"
        d="M2.5,27h14c0.276,0,0.5-0.224,0.5-0.5v-21C17,5.224,16.776,5,16.5,5h-14C2.224,5,2,5.224,2,5.5v21 C2,26.776,2.224,27,2.5,27z M3,6h13v20H3V6z"
      />
      <circle fill="#828282" cx={10} cy={29} r={1} />
      <path
        fill="#828282"
        d="M7.5,4h4C11.776,4,12,3.776,12,3.5S11.776,3,11.5,3h-4C7.224,3,7,3.224,7,3.5S7.224,4,7.5,4z"
      />
    </g>
  </svg>
);

export const SnailIcon = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 511.998 511.998"
    style={{
      enableBackground: "new 0 0 511.998 511.998",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <rect
        x={344.018}
        y={115.907}
        transform="matrix(-0.3353 -0.9421 0.9421 -0.3353 427.7589 549.3062)"
        style={{
          fill: "#FF9811",
        }}
        width={127.28}
        height={15.691}
      />
      <rect
        x={455.612}
        y={60.108}
        transform="matrix(-0.9421 -0.3353 0.3353 -0.9421 858.5919 395.7321)"
        style={{
          fill: "#FF9811",
        }}
        width={15.691}
        height={127.28}
      />
    </g>
    <circle
      style={{
        fill: "#FFA733",
      }}
      cx={386.333}
      cy={63.792}
      r={27.601}
    />
    <path
      style={{
        fill: "#FFFFFF",
      }}
      d="M386.328,80.931c-9.45,0-17.139-7.689-17.139-17.139c0-9.451,7.689-17.14,17.139-17.14 c9.451,0,17.14,7.689,17.14,17.14C403.468,73.243,395.779,80.931,386.328,80.931z"
    />
    <path
      style={{
        fill: "#57565C",
      }}
      d="M386.328,70.47c-3.682,0-6.677-2.995-6.677-6.677c0-3.683,2.995-6.678,6.677-6.678 c3.683,0,6.678,2.995,6.678,6.678C393.006,67.475,390.01,70.47,386.328,70.47z"
    />
    <circle
      style={{
        fill: "#FFA733",
      }}
      cx={484.397}
      cy={63.792}
      r={27.601}
    />
    <path
      style={{
        fill: "#FFFFFF",
      }}
      d="M484.399,80.931c-9.45,0-17.139-7.689-17.139-17.139c0-9.451,7.689-17.14,17.139-17.14 c9.451,0,17.14,7.689,17.14,17.14S493.85,80.931,484.399,80.931z"
    />
    <path
      style={{
        fill: "#57565C",
      }}
      d="M484.399,70.47c-3.682,0-6.677-2.995-6.677-6.677c0-3.683,2.995-6.678,6.677-6.678 c3.683,0,6.678,2.995,6.678,6.678C491.077,67.475,488.081,70.47,484.399,70.47z"
    />
    <path
      style={{
        fill: "#FFB655",
      }}
      d="M489.799,258.538c-0.713-41.844-7.546-74.387-20.307-96.728c-0.44-0.77-0.929-1.511-1.462-2.22 c-1.896-2.512-12.227-15.045-29.604-15.045c-7.532,0-18.828,2.508-29.862,14.452c-8.619,9.331-16.124,22.421-17.225,40.397 c-5.062,82.636-73.6,77.837-135.638,110.78c-111.469,59.193-244.86,107.81-244.86,107.81c-7.657,4.212-11.89,12.74-10.615,21.384 c1.275,8.646,7.788,15.588,16.335,17.412c3.643,0.776,90.348,19.024,188.669,19.026c0.008,0,0.007,0,0.017,0 c72.51,0,132.298-10.011,177.683-29.755c28.931-12.586,52.322-29.319,69.521-49.735c19.468-23.108,31.203-51.059,34.88-83.081 C487.562,311.29,490.299,287.843,489.799,258.538z"
    />
    <path
      style={{
        fill: "#FFA733",
      }}
      d="M487.016,219.582L487.016,219.582c-2.582,31.048-7.763,55.272-8.211,57.328 c-7.371,34.762-23.161,64.216-46.935,87.549c-21.004,20.615-48.418,36.543-81.482,47.342 c-51.867,16.941-118.529,21.702-198.109,14.148c-0.01-0.001-0.009-0.001-0.018-0.001c-42.169-4.004-82.076-10.88-115.313-17.846 c-16.379,6.334-26.109,9.882-26.109,9.882c-7.657,4.212-11.89,12.74-10.615,21.384c1.275,8.646,7.788,15.588,16.335,17.412 c3.643,0.776,90.348,19.024,188.669,19.026c0.008,0,0.007,0,0.017,0c72.51,0,132.298-10.011,177.683-29.755 c28.931-12.586,52.322-29.319,69.521-49.735c19.468-23.108,31.203-51.059,34.88-83.081c0.234-1.944,2.97-25.39,2.47-54.697 C489.559,244.491,488.627,231.498,487.016,219.582z"
    />
    <path
      style={{
        fill: "#CC7400",
      }}
      d="M32.555,295.439C13.145,214.209,63.26,132.624,144.49,113.215 c101.538-24.263,203.518,38.381,227.781,139.919c0,0-36.176,73.895-124.069,66.645c-9.869,31.412-35.514,57-69.866,65.209 C113.35,400.516,48.083,360.423,32.555,295.439z"
    />
    <path
      style={{
        fill: "#AA6100",
      }}
      d="M182.04,140.588c47.872-11.438,95.836-3.554,135.714,18.611 c-44.793-41.943-109.113-61.314-173.263-45.985C63.26,132.623,13.145,214.208,32.555,295.438 c8.914,37.307,34.225,66.404,66.462,81.489c-13.698-14.757-23.9-33.135-28.913-54.114C50.695,241.583,100.81,159.998,182.04,140.588 z"
    />
    <path
      style={{
        fill: "#663A00",
      }}
      d="M96.534,288.826c-4.712-19.721-1.464-40.098,9.15-57.376c10.615-17.277,27.318-29.388,47.041-34.1 c49.837-11.909,100.072,18.948,111.981,68.787c4.266,17.853,4.166,36.402-0.229,54.071c-5.171,0.16-10.545,0.036-16.131-0.419 c4.659-16.233,5.052-33.461,1.097-50.005c-9.898-41.422-51.65-67.069-93.073-57.171c-15.646,3.738-28.898,13.345-37.316,27.051 c-8.419,13.706-10.998,29.87-7.259,45.516c5.972,24.995,31.167,40.471,56.162,34.499c4.214-1.007,8.447,1.593,9.455,5.808 c1.007,4.214-1.594,8.446-5.808,9.455C138.194,342.924,104.517,322.237,96.534,288.826z"
    />
    <g>
      <path
        style={{
          fill: "#AA6100",
        }}
        d="M327.872,268.956c-3.835,0-7.188-2.814-7.755-6.721c-3.18-21.95-12.868-41.66-28.791-58.584 c-2.97-3.156-2.818-8.122,0.337-11.091c3.155-2.971,8.121-2.819,11.091,0.337c18.175,19.316,29.242,41.887,32.894,67.086 c0.621,4.289-2.352,8.269-6.64,8.891C328.626,268.929,328.246,268.956,327.872,268.956z"
      />
      <path
        style={{
          fill: "#AA6100",
        }}
        d="M274.395,186.676c-1.549,0-3.115-0.458-4.483-1.413c-15.066-10.514-27.521-15.065-27.645-15.11 c-4.076-1.47-6.189-5.966-4.718-10.043c1.471-4.077,5.97-6.188,10.043-4.718c0.587,0.211,14.548,5.313,31.3,17.003 c3.553,2.479,4.424,7.371,1.944,10.924C279.311,185.506,276.872,186.676,274.395,186.676z"
      />
    </g>
  </svg>
);


export const CloseIcon = (props) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <svg
      width="35px"
      height="35px"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      fill={isHovered ? "#4679b2" : "#ffffff"} // Change color on hover
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}
    >
      <defs>
        <filter id="drop-shadow" x="-50%" y="-50%" width="200%" height="200%">
          <feDropShadow
            dx="1"
            dy="1"
            stdDeviation="1"
            floodColor="black"
            floodOpacity="0.6"
          />
        </filter>
      </defs>

      {/* Apply the filter to the path */}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 8.707l3.646 3.647.708-.707L8.707 8l3.647-3.646-.707-.708L8 7.293 4.354 3.646l-.707.708L7.293 8l-3.646 3.646.707.708L8 8.707z"
        filter="url(#drop-shadow)" // Apply the drop shadow filter here
      />
    </svg>
  );
};
